import React, { useEffect, useMemo, useState } from "react";
import FlipMove from "react-flip-move";
import {
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { fillIds, onList } from "../../../utils/db";
import { NumberAvatar } from "../../users/NumberAvatar";

export const ContestantsList = ({
  round,
  event,
  role,
  sort = { rate: -1 },
  filter = { approvedJudgesRates: true, guestsJudgesRates: false },
}) => {
  const [judges, setJudges] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (event?.id) {
      const cb1 = onList(["events", event?.id, "judges"].join("/"), setJudges);
      const cb2 = onList("categories", (data) =>
        setCategories((data || []).filter((cat) => cat.enabled))
      );
      return () => {
        cb1();
        cb2();
      };
    }
  }, [event?.id]);

  const users = fillIds(round?.contestants || {});

  const rates = useMemo(
    () =>
      Object.fromEntries(
        users.map((user) => {
          const [sum, count] = Object.entries(user.rates || {}).reduce(
            (prev, [judgeId, judgeRates]) => {
              const judge = judges.find((j) => j.userId === judgeId);
              return judge &&
                ((judge?.approved && filter?.approvedJudgesRates) ||
                  (!judge?.approved && filter?.guestsJudgesRates))
                ? round.ratesSystem === "simple"
                  ? // for simple rates system just count sum of judgeRates
                    [prev[0] + (judgeRates + 1) / 2, prev[1] + 1]
                  : // for rates system with categories calc all voted and enabled categories
                    Object.entries(judgeRates || {}).reduce(
                      (prev2, [catId, catRates]) => {
                        const category = categories.find(
                          (cat) => cat.id === catId
                        );
                        return category
                          ? [
                              prev2[0] + catRates * (category.rate || 1),
                              prev2[1] + 1,
                            ]
                          : prev2;
                      },
                      prev
                    )
                : prev;
            },
            [0, 0] // [sum, count]
          );
          return [
            user.id,
            {
              value: count ? Math.round((sum / count) * 100) / 100 : -Infinity,
              count,
            },
          ];
        })
      ),
    [
      users,
      categories,
      judges,
      round?.ratesSystem,
      filter?.approvedJudgesRates,
      filter?.guestsJudgesRates,
    ]
  );

  return (
    <List>
      <FlipMove>
        {Object.entries(sort || {})
          .reduce(
            (prev, [field, direction]) =>
              prev.sort((a, b) => {
                if (field === "rate") {
                  return rates[a.id].value < rates[b.id].value
                    ? -direction
                    : rates[a.id].value > rates[b.id].value
                    ? direction
                    : 0;
                } else {
                  return a[field] < b[field]
                    ? -direction
                    : a[field] > b[field]
                    ? direction
                    : 0;
                }
              }),
            users
          )
          .map((user) =>
            Object.assign(
              {},
              event?.contestants[user?.contestantId] || {},
              user
            )
          )
          .filter((user) => role && user?.role === role)
          .map((user) => {
            // categories are filtered at the beginning, but judges need to be filtered here at place
            const percents = Math.round(
              (100 * rates[user?.id].count) /
                (judges.filter(
                  (j) =>
                    (j?.approved && filter?.approvedJudgesRates) ||
                    (!j?.approved && filter?.guestsJudgesRates)
                ).length *
                  (round.ratesSystem === "simple" ? 1 : categories.length) ||
                  Infinity)
            );

            return (
              <ListItem key={user?.id}>
                {user?.number && (
                  <ListItemAvatar>
                    <NumberAvatar item={user} />
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={user?.title}
                  secondary={
                    percents > 0 &&
                    percents < 100 && (
                      <LinearProgress
                        variant="determinate"
                        value={percents}
                      ></LinearProgress>
                    )
                  }
                />
                <ListItemSecondaryAction>
                  {!rates[user?.id].count ? "" : rates[user?.id].value}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </FlipMove>
    </List>
  );
};
