import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Row = ({ user, categories = [], judges }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {user.number}
        </TableCell>
        <TableCell component="th" scope="row">
          {user.title}
        </TableCell>
        <TableCell align="right">
          {Math.round(
            Object.values(user?.rates || {})
              .reduce(
                (prev, curr) =>
                  Object.values(curr).reduce(
                    (prev2, curr2) =>
                      curr2 ? [prev2[0] + curr2, prev2[1] + 1] : prev2,
                    prev
                  ),
                [0, 0]
              )
              .reduce((a, b) => a / b) * 100
          ) / 100 || "-"}
        </TableCell>
        {categories.map((category) => {
          return (
            <TableCell
              key={["r1", user.id, "c", category.id].join("-")}
              align="right"
            >
              {Math.round(
                Object.values(user?.rates || {})
                  .reduce(
                    (prev, curr) =>
                      curr[category.id]
                        ? [prev[0] + curr[category.id], prev[1] + 1]
                        : prev,
                    [0, 0]
                  )
                  .reduce((a, b) => a / b) * 100
              ) / 100 || "-"}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={4 + categories.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {Object.entries(user.rates || {}).length ? (
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Judge</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">Total</TableCell>
                      {categories.map((category) => (
                        <TableCell
                          key={["r2", user.id, "c", category.id].join("-")}
                          align="right"
                        >
                          {category.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(user.rates || {}).map(
                      ([judgeId, judgeRates], index) => (
                        <TableRow key={"rate-" + index}>
                          <TableCell colSpan={2} component="th" scope="row">
                            {judges.find((j) => j.userId === judgeId)?.title}
                          </TableCell>
                          <TableCell align="right">
                            {Math.round(
                              Object.values(judgeRates || {})
                                .reduce(
                                  (prev2, curr2) =>
                                    curr2
                                      ? [prev2[0] + curr2, prev2[1] + 1]
                                      : prev2,
                                  [0, 0]
                                )
                                .reduce((a, b) => a / b) * 100
                            ) / 100 || "-"}
                          </TableCell>
                          {categories.map((cat) => (
                            <TableCell
                              key={["t2-r", user.id, "c", cat.id].join("-")}
                              align="right"
                            >
                              {judgeRates[cat.id] || "-"}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Box sx={{ margin: 2, textAlign: "center" }}>No data</Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const UsersScoresTable = ({ users, categories, judges }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Number</TableCell>
            <TableCell>User name</TableCell>
            <TableCell align="right">Total</TableCell>
            {categories.map((category) => (
              <TableCell key={["t1-hc", category.id].join("-")} align="right">
                {category.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <Row
              key={"row-" + index}
              user={user}
              categories={categories}
              judges={judges}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
