import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { EventItem } from "./EventItem";
import { EditEventModal } from "./EditEventModal";
import { addRecord } from "../../utils/db";
import { AuthContext } from "../../contexts/AuthContext";
import { useSettings } from "../../hooks/useSettings";

export const EventsList = ({ events }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { auth } = useContext(AuthContext);
  const { allowCreateNewEvents } = useSettings();

  const onSave = (data) => {
    data.admins = {
      [auth?.user?.uid]: { userId: auth?.user?.uid },
    };
    addRecord("events", data);
  };

  events = events.filter(
    (event) =>
      event.public || Object.keys(event.admins).includes(auth?.user?.uid)
  );

  const canCreate = auth?.user && allowCreateNewEvents;

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Typography variant="h5">Events</Typography>
            {canCreate && (
              <Button onClick={() => setIsVisible(true)}>Add</Button>
            )}
          </Box>
        }
      ></CardHeader>
      <CardContent>
        {events?.length ? (
          <Grid container spacing={2} columns={1}>
            {events.map((event) => (
              <Grid key={"event-" + event.id} item xs={10}>
                <EventItem item={event} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box>
            <Box>There are no events.</Box>
            {canCreate && <Box>Create a new one!</Box>}
          </Box>
        )}

        <EditEventModal
          isNew={true}
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          onSave={onSave}
        />
      </CardContent>
    </Card>
  );
};
