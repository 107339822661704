import { Chip, Stack } from "@mui/material";
import React from "react";
import { danceRolesMapObj } from "../../utils/dictionaries";

export const UserTags = ({ user, groups }) => {
  return (
    <Stack component="span" direction="row" spacing={1}>
      {user?.role && (
        <Chip
          component="span"
          size="small"
          variant="outlined"
          color={danceRolesMapObj[user?.role]?.color || "primary"}
          label={danceRolesMapObj[user?.role]?.title}
        />
      )}
      {user?.group && (
        <Chip
          component="span"
          size="small"
          variant="outlined"
          color="secondary"
          label={groups[user?.group]?.title}
        />
      )}
    </Stack>
  );
};
