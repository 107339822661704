import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import TableChartIcon from "@mui/icons-material/TableChart";
import SettingsIcon from "@mui/icons-material/Settings";
import { useIsAdmin } from "../hooks/useIsAdmin";
import { EventContext } from "../contexts/EventContext";

export const BottomTabs = () => {
  const [value, setValue] = useState(window.location.pathname.split("/")[1]);
  const { eventId } = useContext(EventContext);
  const isAdmin = useIsAdmin();

  useEffect(() => {
    setValue(window.location.pathname.split("/")[1]);
    return () => {};
  }, [eventId]);

  return eventId ? (
    <Paper
      sx={{ zIndex: 2 }}
      className="stickToBottom"
      elevation={3}
      component="footer"
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          component={Link}
          to="/competition"
          value="competition"
          label="Competition"
          icon={<TableChartIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/users"
          value="users"
          label="Contestants"
          icon={<PeopleIcon />}
        />
        {isAdmin && (
          <BottomNavigationAction
            component={Link}
            to="/settings"
            value="settings"
            label="Settings"
            icon={<SettingsIcon />}
          />
        )}
      </BottomNavigation>
    </Paper>
  ) : null;
};
