import React, { useContext } from "react";
import { AppBar as MAppBar, Toolbar, Typography } from "@mui/material";
import MyFirebase from "./firebase/MyFirebase";
import { EventContext } from "../contexts/EventContext";

export const AppBar = () => {
  const eventContext = useContext(EventContext);

  return (
    <MAppBar position="static" color="default">
      <Toolbar>
        <Typography
          sx={{ flexGrow: 1, cursor: "pointer", ":hover": { opacity: "0.7" } }}
          noWrap
          onClick={() => {
            eventContext.setEventId(null);
          }}
        >
          BDSL Contest
        </Typography>
        <MyFirebase />
      </Toolbar>
    </MAppBar>
  );
};
