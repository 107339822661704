import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EventContext } from "../contexts/EventContext";

export const EventRedirect = () => {
  const { eventId } = useParams();
  const { setEventId } = useContext(EventContext);
  useEffect(() => setEventId(eventId));

  return null;
};
