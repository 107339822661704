import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { fillIds, getOneRecord } from "../../../utils/db";

export const SelectByRound = ({ event, round, setSelectedIds }) => {
  const [rounds, setRounds] = useState({});

  useEffect(
    () => getOneRecord(["events", event.id, "rounds"].join("/"), setRounds),
    [event.id]
  );

  return (
    <ButtonGroup size="small" sx={{ paddingBottom: 1 }} orientation="vertical">
      {fillIds(rounds)
        .filter(
          (r) =>
            round?.id !== r?.id &&
            !Object.values(round?.contestants || {}).length
        )
        .map((round) => (
          <Button
            key={"round-" + round.id}
            sx={{ overflowWrap: "anywhere" }}
            onClick={() =>
              setSelectedIds(
                Object.entries(event?.contestants || {})
                  .filter(([id]) =>
                    Object.values(round.contestants).find(
                      (rc) => rc.contestantId === id
                    )
                  )
                  .map(([id]) => id)
              )
            }
          >
            {round.title}
          </Button>
        ))}
    </ButtonGroup>
  );
};
