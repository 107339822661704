import { Button, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { JudgeContext } from "../../contexts/JudgeContext";
import { deleteRecord, onRecord, updateRecord } from "../../utils/db";

export const BecomeJudgeButton = ({ event }) => {
  const { auth } = useContext(AuthContext);
  const { judge, setJudge } = useContext(JudgeContext);
  const path = event?.id && ["events", event.id, "judges"].join("/");

  useEffect(
    () =>
      path && auth?.user?.uid
        ? onRecord([path, auth?.user?.uid].join("/"), setJudge)
        : setJudge(null),
    [path, auth?.user?.uid, setJudge]
  );

  switch (true) {
    case !!judge?.approved:
      return <Typography>Your are approved as Judge</Typography>;
    case !!judge:
      return (
        <Button onClick={() => deleteRecord(path, judge)}>Leave judges</Button>
      );
    case !!auth?.user?.uid:
      return (
        <Button
          onClick={() =>
            path &&
            updateRecord(path, {
              id: auth?.user?.uid,
              userId: auth?.user?.uid,
              title: auth?.user?.displayName || "",
              email: auth?.user?.email || "",
            })
          }
        >
          Become a judge
        </Button>
      );
    default:
      return null;
  }
};
