import React, { useContext, useEffect, useState } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import firebase from "./firebase-config";
import "firebase/auth";
import { Box } from "@mui/system";
import { AuthContext } from "../../contexts/AuthContext";

export default function MyFirebase({ color = "inherit" }) {
  const [user, setUser] = useState(null);
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setAuth({ user });
    });
  }, [setAuth]);

  var provider = new firebase.auth.GoogleAuthProvider();

  const signInPopup = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;

        console.log(token, credential, user);

        setAuth(result);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        console.log(errorCode, errorMessage, email, credential);
        setAuth(null);
      });
  };

  const signOut = () => {
    firebase.auth().signOut();
    console.log("Sign out!");
    setAuth({});
  };

  if (user) {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Avatar
          src={user.photoURL}
          sx={{ width: 28, height: 28, marginRight: 1 }}
        />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}
        >
          <Typography>{user.displayName}</Typography>
          <Button color={color} onClick={signOut}>
            Logout
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Button color={color} onClick={signInPopup}>
      Log in with Google
    </Button>
  );
}
