import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import { fillIds } from "../../../utils/db";

export const SelectByGroup = ({ event, groups, setSelectedIds }) => {
  return (
    <ButtonGroup size="small" sx={{ paddingBottom: 1 }}>
      {fillIds(groups).map((group) => (
        <Button
          key={"group-" + group.id}
          onClick={() =>
            setSelectedIds(
              Object.entries(event?.contestants || {})
                .filter(([, user]) => user.group === group.id)
                .map(([id]) => id)
            )
          }
        >
          {group.title}
        </Button>
      ))}
    </ButtonGroup>
  );
};
