import React from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ukLocale from "date-fns/locale/uk";
import { Routes } from "./Routes";
import { AuthContextProvider } from "./contexts/AuthContext";
import { EventContextProvider } from "./contexts/EventContext";
import { JudgeContextProvider } from "./contexts/JudgeContext";

const theme = createTheme();

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ukLocale}>
        <AuthContextProvider>
          <EventContextProvider>
            <JudgeContextProvider>
              <Routes />
            </JudgeContextProvider>
          </EventContextProvider>
        </AuthContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
