import React, { Fragment, useState } from "react";
// import FlipMove from "react-flip-move";
import {
  Button,
  ButtonGroup,
  Collapse,
  IconButton,
  InputAdornment,
  ListItem,
  TextField,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Clear, Search } from "@mui/icons-material";

export const CollapsableFilteredListItems = ({
  isExpanded,
  values,
  renderItem,
  sortFields = [
    { id: "number", title: "By nubmer" },
    { id: "title", title: "By name" },
    { id: "role", title: "By role" },
  ],
}) => {
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState({ field: "number", direction: 1 });

  return (
    <Fragment key="list">
      <Collapse in={isExpanded} timeout="auto">
        <ListItem>
          <TextField
            size="small"
            value={searchText || ""}
            label="Search"
            placeholder="Number or name"
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {searchText && (
            <IconButton onClick={() => setSearchText("")}>
              <Clear />
            </IconButton>
          )}
        </ListItem>
        <ListItem>
          <ButtonGroup size="small">
            {sortFields.map((field) => (
              <Button
                key={"field-" + field.id}
                onClick={() =>
                  sorting.field === field.id
                    ? setSorting({
                        ...sorting,
                        direction: sorting.direction * -1,
                      })
                    : setSorting({ field: field.id, direction: 1 })
                }
              >
                {field.title}{" "}
                {sorting.field === field.id &&
                  (sorting.direction > 0 ? <ArrowDropUp /> : <ArrowDropDown />)}
              </Button>
            ))}
          </ButtonGroup>
        </ListItem>
      </Collapse>

      {/* <FlipMove> */}
      {values
        .filter(
          (user) =>
            !searchText.length ||
            user.title?.toLowerCase().indexOf(searchText.toLowerCase()) !==
              -1 ||
            user.role?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
            user.number
              ?.toString()
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
        .sort((a, b) =>
          (a[sorting.field] || "") < (b[sorting.field] || "")
            ? -sorting.direction
            : (a[sorting.field] || "") > (b[sorting.field] || "")
            ? sorting.direction
            : 0
        )
        .map(renderItem)}
      {/* </FlipMove> */}
    </Fragment>
  );
};
