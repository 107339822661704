import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Rating,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { getOneRecord, updateRecord } from "../../utils/db";
import { useCategories } from "../../hooks/useCategories";
import firebase from "../firebase/firebase-config";

const auth = firebase.auth();

export const RateDialog = ({ item, basePath, open, onClose }) => {
  const path = basePath && item?.id && [basePath, item.id, "rates"].join("/");
  const [values, setValues] = useState({});

  const categories = useCategories();

  useEffect(
    () =>
      open &&
      path &&
      getOneRecord(path + "/" + auth.currentUser?.uid, setValues),
    [path, open]
  );

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{item?.title} rates</DialogTitle>
      <DialogContent>
        <List sx={{ minWidth: 280 }}>
          {categories.map((cat, index) => {
            return (
              <Fragment key={"rate-" + index}>
                <ListItem>
                  <ListItemText>
                    <Typography component="legend">{cat.title}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Rating
                      size="large"
                      precision={0.5}
                      name={"rating-" + cat.id}
                      value={(values || {})[cat.id] || 0}
                      onChange={(event, newValue) => {
                        updateRecord(path, {
                          [auth.currentUser?.uid + "/" + cat.id]: newValue,
                        });
                        setValues({ ...values, [cat.id]: newValue });
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </Fragment>
            );
          })}
        </List>
        {/* <TextField
          margin="normal"
          id="name"
          label="Comments"
          type="text"
          fullWidth
          multiline
          rows={3}
          variant="outlined"
        /> */}
      </DialogContent>
    </Dialog>
  );
};
