import { createContext, useContext, useEffect, useState } from "react";
import { onRecord } from "../utils/db";
import { AuthContext } from "./AuthContext";
import { EventContext } from "./EventContext";

export const JudgeContext = createContext({
  judge: null,
  setJudge: () => {},
});

export const JudgeContextProvider = ({ children }) => {
  const { eventId } = useContext(EventContext);
  const { auth } = useContext(AuthContext);

  const [judge, setJudge] = useState(null);

  useEffect(
    () =>
      eventId && auth?.user?.uid
        ? onRecord(
            ["events", eventId, "judges", auth?.user?.uid].join("/"),
            setJudge
          )
        : setJudge(null),
    [eventId, auth?.user?.uid]
  );

  return (
    <JudgeContext.Provider value={{ judge, setJudge }}>
      {children}
    </JudgeContext.Provider>
  );
};
