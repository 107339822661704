import React, { useState } from "react";
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

export const CrudListItem = ({
  item,
  fieldsMap,
  onToggleEnabled,
  onItemClick,
  onEdit,
  onDelete,
}) => {
  const id = item?.id;
  const title = item?.title || "";

  const [checked, setChecked] = useState(
    item?.[fieldsMap?.enabled || "enabled"] || false
  );

  const handleToggle = (event) => {
    event.stopPropagation();
    typeof onToggleEnabled == "function" && onToggleEnabled(item, !checked);
    setChecked(!checked);
  };

  const description = Array.isArray(fieldsMap?.description)
    ? fieldsMap?.description
        .map((descr) => item?.[descr])
        .filter((v) => v)
        .join("\n")
    : item?.[fieldsMap?.description || "description"];

  return (
    <ListItemButton
      key={"lib-" + id}
      onClick={() => typeof onItemClick == "function" && onItemClick(item)}
    >
      {item?.avatar && typeof item.avatar === "function" ? (
        item.avatar()
      ) : (
        <ListItemAvatar>
          <Avatar>{item.avatar || (title || "")[0]}</Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        id={`switch-list-label-${id}`}
        primary={typeof title == "function" ? title() : title}
        primaryTypographyProps={{
          textOverflow: "ellipse",
          noWrap: true,
          paddingRight: 14,
        }}
        secondary={description || null}
        secondaryTypographyProps={{
          textOverflow: "ellipse",
          noWrap: true,
          paddingRight: 12,
          whiteSpace: "pre-line",
        }}
      />
      <ListItemSecondaryAction>
        {typeof onToggleEnabled === "function" && (
          <Switch
            edge="end"
            onChange={handleToggle}
            onClick={(event) => event.stopPropagation()}
            checked={checked}
            inputProps={{
              "aria-labelledby": `switch-list-label-${id}`,
            }}
          />
        )}
        {onEdit && (
          <IconButton
            edge="end"
            aria-label="edit"
            onClick={(event) => {
              event.stopPropagation();
              onEdit(item);
            }}
          >
            <Edit />
          </IconButton>
        )}
        {onDelete && (
          <IconButton
            disabled={checked}
            edge="end"
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(item);
            }}
          >
            <Delete />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};
