import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import {
  deleteRecord,
  fillIds,
  getOneRecord,
  updateRecord,
} from "../../utils/db";
import { Box } from "@mui/system";
import { NumberAvatar } from "../users/NumberAvatar";
import { UserTags } from "../users/UserTags";
import { SelectByGroup } from "./contestants/SelectByGroup";
import { SelectByRound } from "./contestants/SelectByRound";

export const ManageContestantsModal = ({ event, round, open, onClose }) => {
  const [groups, setGroups] = useState({});
  const [currentIds, setCurrentIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState(currentIds);

  useEffect(() => {
    let mounted = true;
    const cb = getOneRecord(
      ["events", event.id, "groups"].join("/"),
      (data) => mounted && setGroups(data)
    );
    return () => {
      mounted = false;
      typeof cb === "function" && cb();
    };
  }, [event?.id]);

  useEffect(() => {
    if (open) {
      const ids = Object.values(round?.contestants || {}).map(
        (el) => el.contestantId
      );
      setCurrentIds(ids);
      setSelectedIds(ids);
    }
  }, [open, round?.contestants]);

  const sortByNumber = (a, b) =>
    a.number < b.number ? -1 : a.number > b.number ? 1 : 0;

  const sortedList = (obj) =>
    fillIds(obj?.contestants || {}).sort(sortByNumber);

  const save = () => {
    const toAdd = selectedIds.filter((x) => !currentIds.includes(x));
    const toRemove = currentIds.filter((x) => !selectedIds.includes(x));

    const path = ["events", event.id, "rounds", round.id, "contestants"].join(
      "/"
    );

    const roundContestants = fillIds(round.contestants);

    toRemove.forEach((delId) =>
      deleteRecord(path, {
        id: roundContestants.find((el) => el.contestantId === delId).id,
      })
    );
    toAdd.forEach((id) => updateRecord(path, { id, contestantId: id }));

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Manage contestants</DialogTitle>
      <DialogContent>
        <Box pt={1} pb={4}>
          <Autocomplete
            sx={{ minWidth: 240 }}
            onChange={(event, value) =>
              setSelectedIds(value.map((el) => el.id))
            }
            multiple
            limitTags={5}
            filterSelectedOptions
            disableCloseOnSelect
            id="manage-contestants"
            value={
              sortedList(event).filter((val) => selectedIds.includes(val.id)) ||
              []
            }
            options={sortedList(event)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.number + option.title}
            renderTags={(value, getTagProps) =>
              value
                .sort(sortByNumber)
                .map((option, index) => (
                  <Chip
                    variant="filled"
                    avatar={<Avatar>{option.number}</Avatar>}
                    label={option.title}
                    {...getTagProps({ index })}
                  />
                ))
            }
            renderOption={(props, user) => (
              <ListItem {...props} dense>
                <ListItemAvatar>
                  <NumberAvatar item={user} />
                </ListItemAvatar>
                <ListItemText
                  primary={user.title}
                  secondary={<UserTags user={user} groups={groups} />}
                />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Contestants"
                placeholder="Contestants"
              />
            )}
          />
        </Box>
        <ButtonGroup size="small" sx={{ paddingBottom: 1 }}>
          <Button
            onClick={() =>
              setSelectedIds(Object.keys(event?.contestants || {}))
            }
          >
            All
          </Button>
        </ButtonGroup>
        <SelectByGroup
          event={event}
          groups={groups}
          setSelectedIds={setSelectedIds}
        />
        <SelectByRound
          event={event}
          round={round}
          setSelectedIds={setSelectedIds}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={save}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
