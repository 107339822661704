import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { addRecord, deleteRecord, onList, updateRecord } from "../../utils/db";
import { CrudList } from "../crud/CrudList";

const defaultFieldsMap = {
  enabled: null,
  title: "title",
  description: "description",
};

const defaultAbilities = {
  filter: false,
  add: true,
  edit: true,
  delete: true,
};

export const FullCrud = ({
  path,
  title,
  editTitle,
  fields = [],
  fieldsMap = defaultFieldsMap,
  abilities = defaultAbilities,
  onSuccess = () => {},
}) => {
  fieldsMap = Object.assign({}, defaultFieldsMap, fieldsMap);

  abilities = Object.assign({}, defaultAbilities, abilities);

  const empty = fieldsMap.enabled ? { [fieldsMap.enabled]: false } : {};
  const [values, setValues] = useState([]);
  const [filter, setFilter] = useState("");

  const [data, setData] = useState(empty);

  const [isVisible, setIsVisible] = useState(false);

  const close = () => {
    setIsVisible(false);
  };

  const onEdit = abilities.edit
    ? (item) => {
        setData(item);
        setIsVisible(true);
      }
    : null;

  useEffect(
    () =>
      onList(path, (values) =>
        setValues(
          values.filter((value) =>
            !filter
              ? value
              : Object.values(value).reduce(
                  (prev, v) =>
                    prev || (typeof v == "string" && v.includes(filter)),
                  false
                )
          )
        )
      ),
    [path, filter]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          marginY: 2,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          {abilities.add ? (
            <Button
              onClick={() => {
                setData(empty);
                setIsVisible(true);
              }}
            >
              Add
            </Button>
          ) : null}
          {abilities.filter ? (
            <TextField
              size="small"
              name="filter"
              placeholder="Filter"
              value={filter}
              onChange={(value) => setFilter(value.target.value)}
            />
          ) : null}
        </Box>
      </Box>
      <CrudList
        values={values}
        fieldsMap={fieldsMap}
        onDelete={abilities.delete ? (item) => deleteRecord(path, item) : null}
        onItemClick={onEdit}
        onEdit={onEdit}
        onToggleEnabled={
          fieldsMap.enabled
            ? (item, checked) => {
                updateRecord([path, item.id].join("/"), {
                  [fieldsMap.enabled]: !!checked,
                });
                onSuccess(item);
              }
            : null
        }
      />

      <Dialog open={isVisible} onClose={close}>
        <DialogTitle>
          {!data?.id ? "Add a new" : "Edit"} {editTitle}
        </DialogTitle>
        <DialogContent>
          {fields.map((field, index) => (
            <TextField
              autoFocus={index === 0}
              fullWidth
              margin="dense"
              key={field.name}
              name={field.name}
              type={field.type || "text"}
              label={field.title}
              value={data?.[field.name] || field.default || ""}
              onChange={(value) =>
                setData({ ...data, [field.name]: value.target.value })
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button
            onClick={() => {
              data?.id ? updateRecord(path, data) : addRecord(path, data);
              onSuccess(data);
              close();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
