import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardHeader, Container } from "@mui/material";
import { Box } from "@mui/system";
import { onRecord, updateRecord } from "../utils/db";
import { SimpleRoundContestants } from "../components/judge/SimpleRoundContestants";
import { CategorizedRoundContestants } from "../components/judge/CategorizedRoundContestants";
import { useIsAdminOnEvent } from "../hooks/useIsAdmin";
import { useCategories } from "../hooks/useCategories";
import { EventContext } from "../contexts/EventContext";
import { AppPageWrapper } from "./AppPageWrapper";

export const RoundPage = () => (
  <AppPageWrapper>
    <Page />
  </AppPageWrapper>
);

const Page = () => {
  const [event, setEvent] = useState();
  const [round, setRound] = useState();

  const { eventId } = useContext(EventContext);

  useEffect(() => {
    return eventId
      ? onRecord(["events", eventId].join("/"), setEvent)
      : setEvent(null);
  }, [eventId]);

  useEffect(() => {
    event?.activeRound
      ? setRound({ id: event.activeRound, ...event.rounds[event.activeRound] })
      : setRound(null);
  }, [event]);

  useCategories();
  const isAdmin = useIsAdminOnEvent(event);

  return (
    <Container>
      <Card sx={{ marginTop: 2, marginBottom: 2 }} elevation={3}>
        {/* <Typography>{round?.title}</Typography> */}
        <CardHeader
          // avatar={
          //   <Avatar>
          //     {
          //       roundRatesSystem.find((rs) => rs.id === round?.ratesSystem)
          //         ?.icon
          //     }
          //   </Avatar>
          // }
          title={round?.title}
          subheader={
            isAdmin && (
              <Box display="flex" flexDirection="row-reverse">
                <Button
                  onClick={() => {
                    updateRecord("events", { id: eventId, activeRound: null });
                    updateRecord(["events", eventId, "rounds"].join("/"), {
                      id: event.activeRound,
                      status: "completed",
                    });
                  }}
                >
                  Stop current round
                </Button>
              </Box>
            )
          }
        />

        {round?.ratesSystem === "categories" ? (
          <CategorizedRoundContestants event={event} round={round} />
        ) : (
          <SimpleRoundContestants event={event} round={round} />
        )}
      </Card>
    </Container>
  );
};
