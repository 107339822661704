import React, { useEffect, useMemo, useState } from "react";
import { EditRoundModal } from "./EditRoundModal";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RoundItem } from "./RoundItem";
import { addRecord, onList, updateRecord } from "../../utils/db";
import { useIsAdminOnEvent } from "../../hooks/useIsAdmin";
import { ManageContestantsModal } from "./ManageContestantsModal";

export const RoundsList = ({ event, activeRoundId }) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isManageIsVisible, setIsManageIsVisible] = useState(false);
  const [selectedRound, setSelectedRound] = useState(null);
  const [rounds, setRounds] = useState([]);

  const path = ["events", event?.id, "rounds"].join("/");

  useEffect(() => event?.id && onList(path, setRounds), [event?.id, path]);

  const onSave = (data) => {
    selectedRound ? updateRecord(path, data) : addRecord(path, data);
  };

  const onShowEdit = (data) => {
    setSelectedRound(data);
    setIsEditVisible(true);
  };

  const onShowManageContestants = (data) => {
    setSelectedRound(data);
    setIsManageIsVisible(true);
  };

  const isAdmin = useIsAdminOnEvent(event);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography variant="h6">Rounds</Typography>
        {isAdmin && <Button onClick={() => onShowEdit(null)}>Add</Button>}
      </Box>

      {rounds?.length ? (
        <Grid container spacing={2} columns={1}>
          {rounds.map((round) => (
            <Grid key={"round-" + round.id} item xs={10}>
              <RoundItem
                item={round}
                event={event}
                activeRoundId={activeRoundId}
                onEdit={onShowEdit}
                onShowManageContestants={onShowManageContestants}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box>There is no rounds in this competition</Box>
      )}

      <EditRoundModal
        item={selectedRound}
        isVisible={isEditVisible}
        onClose={() => setIsEditVisible(false)}
        onSave={onSave}
      />
      {useMemo(
        () => (
          <ManageContestantsModal
            event={event}
            round={selectedRound}
            open={isManageIsVisible}
            onClose={() => setIsManageIsVisible(false)}
          />
        ),
        [event, selectedRound, isManageIsVisible]
      )}
    </Box>
  );
};
