import React, { useEffect, useState } from "react";
import { FilterList } from "@mui/icons-material";
import {
  IconButton,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Box } from "@mui/system";
import { fillIds, onRecord } from "../../utils/db";
import { RateDialog } from "./RateDialog";
import { useCategories } from "../../hooks/useCategories";
import { CollapsableFilteredListItems } from "../CollapsableFilteredListItems";
import { NumberAvatar } from "../users/NumberAvatar";
import firebase from "../firebase/firebase-config";

const auth = firebase.auth();

const ContestantsListItem = ({ item, onClick }) => {
  const id = item?.id;
  const title = item?.title || "";

  const categoriesIds = useCategories().map((c) => c.id);

  const value = Math.round(
    (100 * Object.values(item?.rates?.[auth.currentUser?.uid] || {}).length) /
      categoriesIds.length
  );

  return (
    <ListItemButton
      onClick={() => typeof onClick == "function" && onClick(item)}
    >
      {item?.number && (
        <ListItemAvatar>
          <NumberAvatar item={item} />
        </ListItemAvatar>
      )}
      <ListItemText
        id={`switch-list-label-${id}`}
        primary={typeof title == "function" ? title() : title}
        secondary={
          value < 100 && (
            <LinearProgress
              color={"success"}
              variant="determinate"
              value={value}
            ></LinearProgress>
          )
        }
      />
      <ListItemSecondaryAction>
        {item?.rates?.[auth.currentUser?.uid] &&
          (Math.round(
            Object.entries(item.rates[auth.currentUser?.uid])
              .reduce(
                (prev, [catId, curr]) =>
                  categoriesIds.includes(catId)
                    ? [prev[0] + curr, prev[1] + 1]
                    : prev,
                [0, 0]
              )
              .reduce((a, b) => a / b) * 100
          ) / 100 ||
            null)}
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export const CategorizedRoundContestants = ({ event, round }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [users, setUsers] = useState([]);
  const [filterIsShown, setFilterIsShown] = useState(false);

  useEffect(() => {
    if (round?.id) {
      const path = ["events", event.id, "rounds", round.id].join("/");
      return onRecord(path, (data) => {
        setUsers(fillIds(data.contestants || {}));
      });
    }
  }, [event?.id, round?.id]);

  const contestants = fillIds(event?.contestants || {});

  const contestantsPath = [
    "events",
    event.id,
    "rounds",
    round.id,
    "contestants",
  ].join("/");

  return (
    <>
      <List
        subheader={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <ListSubheader>Contestants</ListSubheader>
            <IconButton onClick={() => setFilterIsShown(!filterIsShown)}>
              <FilterList />
            </IconButton>
          </Box>
        }
      >
        <CollapsableFilteredListItems
          key="collapsable-filter"
          isExpanded={filterIsShown}
          values={(users || []).map((value) =>
            Object.assign(
              {},
              contestants.find((c) => c.id === value.contestantId),
              value
            )
          )}
          renderItem={(value, id) => (
            <ContestantsListItem
              key={`list-item-${id}`}
              item={value}
              onClick={(item) => {
                console.log(item);
                setSelectedItem(item);
                setIsVisible(true);
              }}
            />
          )}
        />
      </List>
      <RateDialog
        onClose={() => setIsVisible(false)}
        open={isVisible}
        item={selectedItem}
        basePath={contestantsPath}
      />
    </>
  );
};
