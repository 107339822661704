import React, { useMemo } from "react";
// import {
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const SimpleScoresTable = ({
  users,
  judges,
  filter = { approvedJudgesRates: true, guestsJudgesRates: false },
}) => {
  console.log(users, judges);
  const ratesSystem = "simple";
  const rates = useMemo(
    () =>
      Object.fromEntries(
        users.map((user) => {
          const categories = [];
          const [sum, count] = Object.entries(user.rates || {}).reduce(
            (prev, [judgeId, judgeRates]) => {
              const judge = judges.find((j) => j.userId === judgeId);
              return judge &&
                ((judge?.approved && filter?.approvedJudgesRates) ||
                  (!judge?.approved && filter?.guestsJudgesRates))
                ? ratesSystem === "simple"
                  ? // for simple rates system just count sum of judgeRates
                    [prev[0] + (judgeRates + 1) / 2, prev[1] + 1]
                  : // for rates system with categories calc all voted and enabled categories
                    Object.entries(judgeRates || {}).reduce(
                      (prev2, [catId, catRates]) =>
                        categories.some((cat) => cat.id === catId)
                          ? [prev2[0] + catRates, prev2[1] + 1]
                          : prev2,
                      prev
                    )
                : prev;
            },
            [0, 0] // [sum, count]
          );
          return [
            user.id,
            {
              sum,
              count,
              avg: count ? Math.round((sum / count) * 100) / 100 : -Infinity,
            },
          ];
        })
      ),
    [
      users,
      judges,
      ratesSystem,
      filter?.approvedJudgesRates,
      filter?.guestsJudgesRates,
    ]
  );

  console.log(rates);

  return (
    <DataGrid
      autoHeight
      hideFooter
      isRowSelectable={() => false}
      rowCount={3 + judges.length}
      sortModel={[{ field: "total", sort: "desc" }]}
      columns={[
        { field: "number", headerName: "Number" },
        { field: "title", headerName: "User name", minWidth: "150" },
        { field: "total", headerName: "Total" },
        ...judges.map((judge) => ({
          field: judge.userId,
          headerName: judge.title,
        })),
      ]}
      rows={users.map((user) => {
        const row = {
          id: user.id,
          number: user.number,
          title: user.title,
          total: rates[user.id].sum || (rates[user.id].count ? 0 : undefined),
        };
        judges.forEach(
          (judge) =>
            (row[judge.userId] =
              user.rates?.[judge.userId] !== undefined
                ? (user.rates?.[judge.userId] + 1) / 2
                : undefined)
        );
        return row;
      })}
    />
  );
  // return (
  //   <TableContainer component={Paper}>
  //     <Table aria-label="collapsible table" size="small">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>Number</TableCell>
  //           <TableCell>User name</TableCell>
  //           <TableCell align="right">Total</TableCell>
  //           {judges.map((judge) => (
  //             <TableCell key={["t1-hc", judge.id].join("-")} align="right">
  //               {judge.title}
  //             </TableCell>
  //           ))}
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {users.map((user, index) => (
  //           <TableRow key={"row-" + index}>
  //             <TableCell>{user.number}</TableCell>
  //             <TableCell>{user.title}</TableCell>
  //             <TableCell align="right">{rates[user.id].sum}</TableCell>
  //             {judges.map((judge) => (
  //               <TableCell key={["t1-hc", judge.id].join("-")} align="right">
  //                 {user.rates?.[judge.userId]
  //                   ? (user.rates?.[judge.userId] + 1) / 2
  //                   : "-"}
  //               </TableCell>
  //             ))}
  //           </TableRow>
  //         ))}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // );
};
