import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { roundRatesSystem, roundTypes } from "../../utils/dictionaries";

export const EditRoundModal = ({ item, isVisible, onClose, onSave }) => {
  const merge = (item) => Object.assign({ type: "jnj" }, item);
  const [data, setData] = useState(merge(item));

  const close = () => {
    setData(null);
    onClose();
  };

  useEffect(() => isVisible && setData(merge(item)), [item, isVisible]);

  return (
    <Dialog open={isVisible} onClose={close} onBackdropClick={close}>
      <DialogTitle>{!item ? "Add a new" : "Edit"} round</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          fullWidth
          id="title"
          label="Title"
          error={!data?.title}
          value={data?.title || ""}
          onChange={(event) => setData({ ...data, title: event.target.value })}
        />
        <TextField
          select
          margin="normal"
          size="small"
          fullWidth
          id="outlined-select-round-rates-system"
          label="Round rates system"
          error={!data?.ratesSystem}
          value={data?.ratesSystem || ""}
          onChange={(event) =>
            setData({ ...data, ratesSystem: event.target.value })
          }
          helperText="Please select round rates system"
        >
          {roundRatesSystem.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          margin="normal"
          size="small"
          fullWidth
          id="outlined-select-round-type"
          label="Round type"
          disabled
          value={"jnj"}
          onChange={(event) => setData({ ...data, type: event.target.value })}
          helperText="Please select round type"
        >
          {roundTypes.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button type="reset" onClick={close}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!data?.title || !data?.ratesSystem}
          onClick={() => {
            onSave({ ...data });
            close();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
