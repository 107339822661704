import React, { useContext } from "react";
// import { List, ListItem, ListItemText, Typography } from "@mui/material";
// import { Box } from "@mui/system";
import { FullCrud } from "../components/crud/FullCrud";
import { EventContext } from "../contexts/EventContext";
import { resetCategoriesCache } from "../hooks/useCategories";
import { AppPageWrapper } from "./AppPageWrapper";
// import { onRecord } from "../utils/db";

export const SettingsPage = () => (
  <AppPageWrapper>
    <Page />
  </AppPageWrapper>
);

const Page = () => {
  // const [settings, setSettings] = useState({});

  const { eventId } = useContext(EventContext);

  // useEffect(
  //   () =>
  //     onRecord(["events", eventId, "settings"].join("/"), setSettings, false),
  //   [eventId]
  // );

  return (
    <>
      <FullCrud
        title="Criteria"
        editTitle="criterion"
        path="categories"
        fieldsMap={{ enabled: "enabled" }}
        fields={[
          { name: "title", title: "Criterion name" },
          { name: "description", title: "Description" },
          { name: "rate", title: "Rate", default: "1" },
        ]}
        onSuccess={() => resetCategoriesCache()}
      />
      {eventId && (
        <FullCrud
          title="Categories"
          editTitle="category"
          path={["events", eventId, "groups"].join("/")}
          fields={[{ name: "title", title: "Category name" }]}
        />
      )}
      {eventId && (
        <FullCrud
          title="Judges"
          editTitle="judge"
          path={["events", eventId, "judges"].join("/")}
          fieldsMap={{ enabled: "approved", description: ["email", "userId"] }}
          fields={[{ name: "title", title: "Name" }]}
          abilities={{ filter: true, add: false }}
        />
      )}
      {/* {eventId && (
        <Box>
          <Typography variant="h6">Preferences</Typography>
          <List>
            <ListItem>
              <ListItemText title={}/>
            </ListItem>
          </List>
        </Box>
      )} */}
    </>
  );
};
