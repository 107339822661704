import React, { useContext, useEffect, useState } from "react";
import { Add, Delete, Edit, FilterList } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  addRecord,
  deleteRecord,
  onList,
  onRecord,
  updateRecord,
} from "../utils/db";
import { EditContestantModal } from "../components/users/EditContestantModal";
import { useIsAdmin } from "../hooks/useIsAdmin";
import { CollapsableFilteredListItems } from "../components/CollapsableFilteredListItems";
import { NumberAvatar } from "../components/users/NumberAvatar";
import { UserTags } from "../components/users/UserTags";
import { EventContext } from "../contexts/EventContext";
import { AppPageWrapper } from "./AppPageWrapper";

export const ContestantsPage = () => (
  <AppPageWrapper>
    <Page />
  </AppPageWrapper>
);

const Page = () => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [filterIsShown, setFilterIsShown] = useState(false);
  const eventContext = useContext(EventContext);

  const path = ["events", eventContext.eventId, "contestants"].join("/");
  const groupsPath = ["events", eventContext.eventId, "groups"].join("/");

  useEffect(() => onList(path, setUsers), [path]);
  useEffect(() => onRecord(groupsPath, setGroups), [groupsPath]);

  const nextNumber =
    users.reduce(
      (prev, curr) => Math.max(prev?.number || 0, curr?.number || 0),
      0
    ) + 1;

  const onSave = (data) => {
    if (
      !currentUser?.id &&
      users.some((user) => user?.number === data?.number)
    ) {
      throw new Error("Number " + data.number + " is already in use");
    }

    !currentUser?.id ? addRecord(path, data) : updateRecord(path, data);
  };

  const isAdmin = useIsAdmin();

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Typography variant="h5">Contestants</Typography>
            <Stack direction="row">
              {isAdmin && (
                <IconButton
                  onClick={() => {
                    setCurrentUser({ number: nextNumber });
                    setIsVisible(true);
                  }}
                >
                  <Add />
                </IconButton>
              )}
              <IconButton onClick={() => setFilterIsShown(!filterIsShown)}>
                <FilterList />
              </IconButton>
            </Stack>
          </Box>
        }
      ></CardHeader>
      {users.length ? (
        <List>
          <CollapsableFilteredListItems
            isExpanded={filterIsShown}
            values={users}
            sortFields={[
              { id: "number", title: "By nubmer" },
              { id: "title", title: "By name" },
              { id: "role", title: "By role" },
              { id: "group", title: "By category" },
            ]}
            renderItem={(user) => (
              <ListItemButton
                dense
                key={"user-" + user.id}
                onClick={() => {
                  isAdmin && setCurrentUser(user);
                  isAdmin && setIsVisible(true);
                }}
              >
                <ListItemAvatar>
                  <NumberAvatar item={user} />
                </ListItemAvatar>
                <ListItemText
                  primary={user?.title}
                  secondary={<UserTags user={user} groups={groups} />}
                  secondaryTypographyProps={{ noWrap: true, paddingRight: 16 }}
                />
                {isAdmin && (
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setCurrentUser(user);
                      setIsVisible(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
                {isAdmin && (
                  <IconButton
                    edge="end"
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteRecord(path, user);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </ListItemButton>
            )}
          />
        </List>
      ) : (
        <Box>
          <Box>There are no contestants.</Box>
          {isAdmin && <Box>Add a new one!</Box>}
        </Box>
      )}

      <EditContestantModal
        eventId={eventContext.eventId}
        isNew={!currentUser?.id}
        item={currentUser}
        open={isVisible}
        onClose={() => setIsVisible(false)}
        onSave={onSave}
      />
    </Card>
  );
};
