import React, { createRef, useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  Collapse,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  IconButton,
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Delete,
  Edit,
  FormatListNumbered,
  GroupAdd,
  MoreVert,
  PlayArrow,
  PlayCircleOutlined,
  Stop,
  StopCircleOutlined,
} from "@mui/icons-material";
import { blue, green, orange } from "@mui/material/colors";
import { roundRatesSystem, roundTypesMap } from "../../utils/dictionaries";
import { deleteRecord, onRecord, updateRecord } from "../../utils/db";
import { useIsAdminOnEvent } from "../../hooks/useIsAdmin";
import { ContestantsList } from "./contestants/ContestantsList";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import { ExpandMoreButton } from "../parts/ExpandMoreButton";

const MoreMenuIcon = ({
  startAction,
  stopAction,
  scoresAction,
  editAction,
  deleteAction,
  manageContestants,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {startAction && (
          <MenuItem onClick={startAction}>
            <ListItemIcon>
              <PlayArrow />
            </ListItemIcon>
            <ListItemText>Start</ListItemText>
          </MenuItem>
        )}
        {stopAction && (
          <MenuItem onClick={stopAction}>
            <ListItemIcon>
              <Stop />
            </ListItemIcon>
            <ListItemText>Stop</ListItemText>
          </MenuItem>
        )}
        {scoresAction && (
          <MenuItem onClick={scoresAction}>
            <ListItemIcon>
              <FormatListNumbered />
            </ListItemIcon>
            <ListItemText>Scores</ListItemText>
          </MenuItem>
        )}
        {manageContestants && (
          <MenuItem onClick={manageContestants}>
            <ListItemIcon>
              <GroupAdd />
            </ListItemIcon>
            <ListItemText>Manage contestants</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={editAction}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        {deleteAction && (
          <MenuItem onClick={deleteAction}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const roundAvatarColor = (status) => {
  switch (status) {
    case "completed":
      return blue[500];
    case "running":
      return orange[500];
    default:
      return green[500];
  }
};

export const RoundItem = ({
  event,
  item = {},
  activeRoundId = null,
  onEdit = (round) => {},
  onShowManageContestants = (round) => {},
}) => {
  const gridRef = createRef();
  const [round, setRound] = useState(item);
  const [expanded, setExpanded] = useState(round?.status === "running");
  const [filter, setFilter] = useState({
    approvedJudgesRates: true,
    guestsJudgesRates: false,
  });

  const history = useHistory();
  const path = ["events", event.id, "rounds"].join("/");

  useEffect(
    () =>
      path && item?.id ? onRecord([path, item.id].join("/"), setRound) : null,
    [item?.id, path]
  );

  useEffect(() => {
    if (round?.status === "running") {
      setExpanded(true);
      window.scrollTo({
        top: gridRef.current?.offsetTop - 4,
        behavior: "smooth",
      });
    }
  }, [round?.status, gridRef]);

  const isAdmin = useIsAdminOnEvent(event);
  const isIdle = round?.status !== "running";

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const manageContestants = () => {
    onShowManageContestants(round);
  };

  const invertFilter = (name) =>
    setFilter({ ...filter, [name]: !filter?.[name] });

  const start = () => {
    updateRecord("events", { id: event.id, activeRound: item.id });
    updateRecord(path, { id: item.id, status: "running" });
  };
  const stop = () => {
    updateRecord("events", { id: event.id, activeRound: null });
    updateRecord(path, { id: item.id, status: "completed" });
  };
  const scores = () => {
    history.push(
      ["", "events", event.id, "rounds", round?.id, "scores"].join("/")
    );
  };
  const deleteRound = () => deleteRecord(path, round);

  const FilterButton = ({ name, children }) => (
    <Button fullWidth variant="text" onClick={() => invertFilter(name)}>
      <Checkbox checked={filter?.[name]} />
      {children}
    </Button>
  );

  return (
    <Card elevation={3} ref={gridRef}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: roundAvatarColor(round?.status) }}>
            {roundRatesSystem.find((rs) => rs.id === round?.ratesSystem)?.icon}
          </Avatar>
        }
        action={
          isAdmin && (
            <MoreMenuIcon
              startAction={isIdle && !activeRoundId && start}
              stopAction={round?.status === "running" && stop}
              scoresAction={round?.status === "completed" && scores}
              editAction={() => onEdit(round)}
              deleteAction={isIdle && deleteRound}
              manageContestants={isIdle && manageContestants}
            />
          )
        }
        title={round?.title || ""}
        subheader={roundTypesMap[round?.type] || ""}
      />
      <CardActions disableSpacing>
        {isAdmin && isIdle && (
          <IconButton onClick={manageContestants}>
            <GroupAdd />
          </IconButton>
        )}
        {isAdmin &&
          (round?.status === "running" ? (
            <IconButton onClick={stop}>
              <StopCircleOutlined />
            </IconButton>
          ) : activeRoundId ? null : (
            <IconButton onClick={start}>
              <PlayCircleOutlined />
            </IconButton>
          ))}
        {round?.status === "completed" && (
          <IconButton onClick={scores}>
            <FormatListNumbered />
          </IconButton>
        )}

        <ExpandMoreButton
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMoreButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container rowSpacing={2} columns={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={1} sm={1} md={1}>
            <Box pl={2} pr={2}>
              <Typography variant="h6">Leaders</Typography>
            </Box>
            <ContestantsList
              role="leader"
              round={round}
              event={event}
              filter={filter}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Box pl={2} pr={2}>
              <Typography variant="h6">Followers</Typography>
            </Box>
            <ContestantsList
              role="follower"
              round={round}
              event={event}
              filter={filter}
            />
          </Grid>
          <Grid item xs={1} sm={2} md={2}>
            <Box pl={2} pr={2} pb={2}>
              <ButtonGroup fullWidth>
                <FilterButton name="approvedJudgesRates">Judges</FilterButton>
                <FilterButton name="guestsJudgesRates">Guests</FilterButton>
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </Card>
  );
};
