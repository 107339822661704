import { BarChart, ThumbsUpDown } from "@mui/icons-material";

export const map = (arr) =>
  Object.fromEntries(arr.map((el) => [el.id, el.title]));

export const mapObj = (arr) => Object.fromEntries(arr.map((el) => [el.id, el]));

export const danceRoles = [
  { id: "leader", title: "Leader", color: "success" },
  { id: "follower", title: "Follower", color: "warning" },
];

export const danceRolesMap = map(danceRoles);
export const danceRolesMapObj = mapObj(danceRoles);

export const roundTypes = [
  { id: "jnj", title: "Jack-n-Jill" },
  { id: "couple", title: "Couples" },
];

export const roundTypesMap = map(roundTypes);

export const roundRatesSystem = [
  { id: "simple", title: "Yes/No", icon: <ThumbsUpDown /> },
  { id: "categories", title: "Categories", icon: <BarChart /> },
];

export const roundRatesSystemMap = map(roundRatesSystem);
