import React from "react";
import { Container } from "@mui/material";
import { Box } from "@mui/system";

export const AppPageWrapper = ({ children }) => (
  <Container component="main">
    <Box pb={8} pt={1}>
      {children}
    </Box>
  </Container>
);
