import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { getOneRecord, updateRecord } from "../../utils/db";
import firebase from "../firebase/firebase-config";

const auth = firebase.auth();

export const YesNoDialog = ({
  item,
  basePath,
  open,
  onClose,
  judgeRate = 1,
}) => {
  const path = [basePath, item?.id, "rates"].join("/");

  const [value, setValue] = useState(null);

  useEffect(
    () =>
      open &&
      item?.id &&
      getOneRecord(path + "/" + auth.currentUser?.uid, setValue),
    [path, open, item?.id]
  );

  const save = (newValue) => {
    updateRecord(path, {
      [auth.currentUser?.uid]: newValue * judgeRate,
    });
    setValue(newValue * judgeRate);
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        {item?.title} - {item?.number || item?.avatar}
      </DialogTitle>
      <Card>
        <CardContent>
          <ButtonGroup
            size="large"
            aria-label="outlined yes no maybe button group"
          >
            <Button
              variant={value < 0 ? "contained" : "outlined"}
              onClick={() => save(-1)}
              color="error"
            >
              No 😢
            </Button>
            {/* ☹️ */}
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              onClick={() => save(0)}
            >
              May be😐
            </Button>
            {/* 😏 */}
            <Button
              variant={value > 0 ? "contained" : "outlined"}
              onClick={() => save(1)}
              color="success"
            >
              Yes! 😍
            </Button>
          </ButtonGroup>
        </CardContent>
      </Card>
    </Dialog>
  );
};
