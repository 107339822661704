import { createContext, useState } from "react";

const STORAGE_KEY = "active_event_id";

export const EventContext = createContext({
  eventId: null,
  setEventId: () => {},
});

export const EventContextProvider = ({ children }) => {
  const [eventId, setEventId] = useState(
    () => sessionStorage.getItem(STORAGE_KEY)
  );

  const setId = (val) => {
    val
      ? sessionStorage.setItem(STORAGE_KEY, val)
      : sessionStorage.removeItem(STORAGE_KEY);
    return setEventId(val);
  };

  return (
    <EventContext.Provider value={{ eventId, setEventId: setId }}>
      {children}
    </EventContext.Provider>
  );
};
