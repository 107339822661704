import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

if (!firebase.apps.length) {
  const firebaseConfig = {
    apiKey: "AIzaSyAuuOUwCOKMUpEWfj_Pod5AtpfAEZzTUXo",
    authDomain: "bdsl-jury.firebaseapp.com",
    databaseURL:
      "https://bdsl-jury-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bdsl-jury",
    storageBucket: "bdsl-jury.appspot.com",
    messagingSenderId: "409182393084",
    appId: "1:409182393084:web:d20a9e7b8890393074fd79",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

if (window.location?.hostname === "localhost" || window.location?.hostname?.match(/^(192|127)\..+/)) {
  // console.log(312123);
  firebase.auth().useEmulator(`http://${window.location.hostname}:9099/`);
  firebase.database().useEmulator(window.location.hostname, "9098");
}

export default firebase;
