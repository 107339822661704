import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AppBar } from "./components/AppBar";
import { BottomTabs } from "./components/BottomTabs";
import { EventContext } from "./contexts/EventContext";
import { JudgeContext } from "./contexts/JudgeContext";
import { CompetitionPage } from "./pages/CompetitionPage";
import { ContestantsPage } from "./pages/ContestantsPage";
import { EventRedirect } from "./pages/EventRedirect";
import { EventsPage } from "./pages/EventsPage";
import { RoundPage } from "./pages/RoundPage";
import { ScoresPage } from "./pages/ScoresPage";
import { SettingsPage } from "./pages/SettingsPage";
import { onRecord } from "./utils/db";

export const Routes = () => {
  const { eventId } = useContext(EventContext);
  const isVoting = useHasActiveRound({ id: eventId });

  const appBar = <AppBar />;
  const wrappedPaths = ["/competition", "/users", "/settings"];

  return (
    <Router>
      <Route path={wrappedPaths}>{appBar}</Route>
      <Route exact path={"/events"}>
        {appBar}
      </Route>
      <Switch>
        {isVoting && [
          <Route path="/vote" key="/vote">
            <RoundPage />
          </Route>,
          <Redirect to="/vote" key="redirect" />,
        ]}
        <Route path="/events/:eventId/rounds/:roundId/scores">
          <ScoresPage />
        </Route>
        {eventId && [
          <Route path="/competition" key="/competition">
            <CompetitionPage />
          </Route>,
          <Route path="/users" key="/users">
            <ContestantsPage />
          </Route>,
          <Route path="/settings" key="/settings">
            <SettingsPage />
          </Route>,
          <Redirect to="/competition" key="redirect" />,
        ]}
        <Route path="/events/:eventId">
          <EventRedirect />
        </Route>
        <Route path="/events">
          <EventsPage />
        </Route>
        <Redirect to="/events" />
      </Switch>
      <Route path={wrappedPaths}>
        <BottomTabs />
      </Route>
    </Router>
  );
};

const useHasActiveRound = ({ id: eventId }) => {
  const [activeRound, setActiveRound] = useState(null);
  const { judge } = useContext(JudgeContext);

  useEffect(
    () =>
      eventId
        ? onRecord(["events", eventId, "activeRound"].join("/"), setActiveRound)
        : setActiveRound(null),
    [eventId]
  );

  return eventId && activeRound && judge;
};
