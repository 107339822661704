import { useContext, useEffect, useMemo, useState } from "react";
import { getRecords } from "../utils/db";
import { EventContext } from "../contexts/EventContext";
import { AuthContext } from "../contexts/AuthContext";

export const useIsAdminOnEvent = (event) => {
  const { auth } = useContext(AuthContext);

  return useMemo(
    () =>
      Object.values(event?.admins || {}).some(
        (a) => a.userId === auth?.user?.uid
      ),
    [event?.admins, auth?.user]
  );
};

export const useIsAdmin = () => {
  const [admins, setAdmins] = useState([]);

  const { eventId } = useContext(EventContext);
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (eventId) {
      return getRecords(["events", eventId, "admins"].join("/"), setAdmins);
    }
  }, [eventId]);

  return useMemo(
    () => (admins || []).find((a) => a.userId === auth?.user?.uid),
    [admins, auth?.user]
  );
};
