import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BecomeJudgeButton } from "../components/judge/BecomeJudgeButton";
import { RoundsList } from "../components/round/RoundsList";
import { onRecord } from "../utils/db";
import { EventContext } from "../contexts/EventContext";
import { AppPageWrapper } from "./AppPageWrapper";

export const CompetitionPage = () => (
  <AppPageWrapper>
    <Page />
  </AppPageWrapper>
);

const Page = () => {
  const [activeRound, setActiveRound] = useState(null);
  const [event, setEvent] = useState({});
  const { eventId, setEventId } = useContext(EventContext);

  useEffect(() => {
    let mounted = true;
    const cb = eventId
      ? onRecord(["events", eventId].join("/"), (record) => {
          if (!record) {
            setEventId(null);
            setEvent({});
          } else if (mounted) {
            event?.id !== record?.id && setEvent(record);
            record.activeRound !== activeRound &&
              setActiveRound(record.activeRound);
          }
        })
      : setEvent({});
    return () => {
      mounted = false;
      typeof cb === "function" && cb();
    };
  }, [eventId, event?.id, activeRound, setEventId]);

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Typography variant="h5">{event?.title}</Typography>
            <BecomeJudgeButton event={event} />
          </Box>
        }
        sx={{ whiteSpace: "pre-line", display: "block" }}
        subheader={event?.description}
      ></CardHeader>
      <CardContent>
        <RoundsList event={event} activeRoundId={activeRound} />
      </CardContent>
    </Card>
  );
};
