import { useEffect } from "react";
import { onList } from "../utils/db";

const storage = { data: [] };

export const useCategories = () => {
  useEffect(
    () =>
      !storage.data.length &&
      onList("categories", (data) => (storage.data = data || [])),
    []
  );

  return storage.data.filter(
    (category) => category.enabled === undefined || category.enabled
  );
};

export const resetCategoriesCache = () => {
  storage.data = [];
};
