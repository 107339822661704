import React, { useEffect, useState } from "react";
import {
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Block, Check, FilterList, HelpOutline } from "@mui/icons-material";
import { Box } from "@mui/system";
import { fillIds, onRecord } from "../../utils/db";
import { YesNoDialog } from "./YesNoDialog";
import firebase from "../firebase/firebase-config";
import { CollapsableFilteredListItems } from "../CollapsableFilteredListItems";
import { NumberAvatar } from "../users/NumberAvatar";

const auth = firebase.auth();

const AnsweredIcon = ({ value }) => {
  if (value === undefined || value === null) {
    return null;
  }
  switch (true) {
    case value < 0:
      return <Block />;
    case value === 0:
      return <HelpOutline />;
    case value > 0:
      return <Check />;
    default:
      return null;
  }
};

const ContestantsListItem = ({ item, onClick }) => {
  const id = item?.id;
  const title = item?.title || "";

  return (
    <ListItemButton
      onClick={() => typeof onClick == "function" && onClick(item)}
    >
      {item?.number && (
        <ListItemAvatar>
          <NumberAvatar item={item} />
        </ListItemAvatar>
      )}
      <ListItemText
        id={`switch-list-label-${id}`}
        primary={typeof title == "function" ? title() : title}
      />
      <ListItemSecondaryAction>
        <AnsweredIcon value={item?.rates?.[auth.currentUser?.uid]} />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export const SimpleRoundContestants = ({ event, round, judgeRate }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [users, setUsers] = useState([]);
  const [filterIsShown, setFilterIsShown] = useState(false);

  useEffect(() => {
    if (round?.id) {
      const path = ["events", event?.id, "rounds", round.id].join("/");
      return onRecord(path, (data) => {
        setUsers(fillIds(data?.contestants || {}));
      });
    }
  }, [event?.id, round?.id]);

  const contestantsPath = [
    "events",
    event?.id,
    "rounds",
    round?.id,
    "contestants",
  ].join("/");

  const contestants = fillIds(event?.contestants || {});

  return (
    <>
      <List
        subheader={
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <ListSubheader>Contestants</ListSubheader>
            <IconButton onClick={() => setFilterIsShown(!filterIsShown)}>
              <FilterList />
            </IconButton>
          </Box>
        }
      >
        <CollapsableFilteredListItems
          key="collapsable-filter"
          isExpanded={filterIsShown}
          values={(users || []).map((value) =>
            Object.assign(
              {},
              contestants.find((c) => c.id === value.contestantId),
              value
            )
          )}
          renderItem={(value, id) => (
            <ContestantsListItem
              key={`list-item-${id}`}
              item={value}
              onClick={(item) => {
                setSelectedItem(item);
                setIsVisible(true);
              }}
            />
          )}
        />
      </List>
      <YesNoDialog
        onClose={() => setIsVisible(false)}
        open={isVisible}
        item={selectedItem}
        basePath={contestantsPath}
        judgeRate={judgeRate}
      />
    </>
  );
};
