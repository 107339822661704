import {
  Card,
  CardContent,
  CardHeader,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SimpleScoresTable } from "../components/users/SimpleScoresTable";
import { UsersScoresTable } from "../components/users/UsersScoresTable";
import { fillIds, onList, onRecord } from "../utils/db";

export const ScoresPage = () => {
  const { eventId, roundId } = useParams();

  const [event, setEvent] = useState();
  const [round, setRound] = useState();
  const [categories, setCategories] = useState([]);

  useEffect(() => onList("categories", setCategories), []);

  useEffect(
    () => onRecord(["events", eventId].join("/"), setEvent, false),
    [eventId]
  );

  useEffect(
    () =>
      onRecord(
        ["events", eventId, "rounds", roundId].join("/"),
        setRound,
        false
      ),
    [eventId, roundId]
  );

  return (
    <Container sx={{ paddingY: 1 }}>
      <Card>
        <CardHeader title={round?.title} subheader={event?.title} />
        <CardContent sx={{height: "1000"}}>
          {round &&
            (round.ratesSystem === "simple" ? (
              <SimpleScoresTable
                users={fillIds(round?.contestants).map((user) =>
                  Object.assign(
                    {},
                    event?.contestants?.[user.contestantId],
                    user
                  )
                )}
                judges={fillIds(event?.judges)}
              />
            ) : (
              <UsersScoresTable
                users={fillIds(round?.contestants).map((user) =>
                  Object.assign(
                    {},
                    event?.contestants?.[user.contestantId],
                    user
                  )
                )}
                categories={categories}
                judges={fillIds(event?.judges)}
              />
            ))}
        </CardContent>
      </Card>
    </Container>
  );
};
