import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { danceRoles } from "../../utils/dictionaries";
import { onList } from "../../utils/db";

export const EditContestantModal = ({
  eventId,
  isNew,
  item,
  open,
  onClose,
  onSave,
}) => {
  const [data, setData] = useState(item || {});
  const [groups, setGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const close = () => {
    setData(null);
    setErrorMessage("");
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      console.log(data);
      onSave({ ...data });
      close();
    } catch (error) {
      setErrorMessage("Error: " + (error.message || error));
    }
  };

  const path = ["events", eventId, "groups"].join("/");
  useEffect(() => onList(path, setGroups), [path]);
  useEffect(() => setData(item), [item, open]);

  return (
    <Dialog open={open} onClose={close} onBackdropClick={close}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {isNew ? "Add" : "Edit"} {item?.title || "user"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            // size="small"
            fullWidth
            id="title"
            label="First and Last names"
            value={data?.title || ""}
            onChange={(event) =>
              setData({ ...data, title: event.target.value })
            }
          />
          <TextField
            select
            margin="normal"
            // size="small"
            fullWidth
            id="outlined-select-role"
            label="Dance role"
            value={data?.role || ""}
            onChange={(event) => setData({ ...data, role: event.target.value })}
            helperText="Please select dance role"
          >
            {danceRoles.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin="dense"
            // size="small"
            fullWidth
            id="outlined-select-group"
            label="Dance category"
            value={data?.group || ""}
            onChange={(event) =>
              setData({ ...data, group: event.target.value })
            }
            helperText="Please select dance category"
          >
            {(groups || []).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="dense"
            // size="small"
            fullWidth
            id="number"
            label="Number"
            value={data?.number || ""}
            onChange={(event) =>
              setData({ ...data, number: parseInt(event.target.value) })
            }
          />
          <Typography color="red">{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" onClick={handleSubmit}>
            Ok
          </Button>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
