import React from "react";
import { List, ListSubheader, Typography } from "@mui/material";
import { CrudListItem } from "./CrudListItem";
import { Box } from "@mui/system";

export const CrudList = ({
  title,
  values = [],
  fieldsMap,
  onItemClick,
  onToggleEnabled,
  onEdit,
  onDelete,
  listItem = null,
}) => {

  return (values || []).length ? (
    <List
      // sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      subheader={<ListSubheader>{title}</ListSubheader>}
    >
      {(values || []).map((value, id) => {
        const props = {
          key: `list-item-${id}`,
          item: value,
          onItemClick,
          onToggleEnabled,
          onEdit,
          onDelete,
          fieldsMap,
        };

        return listItem ? (
          React.createElement(listItem, props)
        ) : (
          <CrudListItem {...props} />
        );
      })}
    </List>
  ) : (
    <Box>
      <Typography style={{ textAlign: "center" }}>
        There are no items in this list.
      </Typography>
      <Typography style={{ textAlign: "center" }}>
        Click "Add" button to add a new one.
      </Typography>
    </Box>
  );
};
