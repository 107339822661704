import React, { useContext, useState } from "react";
import {
  Alert,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { EditEventModal } from "./EditEventModal";
import { Edit, Share } from "@mui/icons-material";
import { useIsAdminOnEvent } from "../../hooks/useIsAdmin";
import { updateRecord } from "../../utils/db";
import { EventContext } from "../../contexts/EventContext";

export const EventItem = ({ item = {} }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [snackBarIsVisible, setSnackBarIsVisible] = useState(false);
  const eventContext = useContext(EventContext);

  const handleSnackBarClose = () => {
    setSnackBarIsVisible(false);
  };

  const isAdmin = useIsAdminOnEvent(item);
  const url = [window.location.toString().trim("/"), item?.id].join("/");
  const title =
    (item?.startDate
      ? "[" + new Date(item.startDate).toLocaleDateString() + "] "
      : "") + (item?.title || "");

  return (
    <Card
      elevation={3}
      sx={{ backgroundColor: item?.public ? "white" : "whitesmoke" }}
    >
      <CardActionArea onClick={() => eventContext.setEventId(item?.id)}>
        <CardHeader
          title={title}
          subheader={item?.description || ""}
          subheaderTypographyProps={{ whiteSpace: "pre-line" }}
        />
      </CardActionArea>
      <CardActions disableSpacing>
        {navigator.clipboard && (
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(url);
              setSnackBarIsVisible(true);
            }}
          >
            <Share />
          </IconButton>
        )}
        {isAdmin && (
          <IconButton onClick={() => setIsVisible(true)}>
            <Edit />
          </IconButton>
        )}
        {!navigator.clipboard && <Typography fontSize={14}>{url}</Typography>}
      </CardActions>
      <Snackbar
        open={snackBarIsVisible}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          URL copied!
        </Alert>
      </Snackbar>
      <EditEventModal
        item={item}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        onSave={(data) => updateRecord("events", data)}
      />
    </Card>
  );
};
