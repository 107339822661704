import React, { useEffect, useState } from "react";
import { EventsList } from "../components/event/EventsList";
import { onList } from "../utils/db";
import { AppPageWrapper } from "./AppPageWrapper";

export const EventsPage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    return onList("events", setEvents);
  }, []);

  return (
    <AppPageWrapper>
      <EventsList events={events} />
    </AppPageWrapper>
  );
};
