import firebase from "../components/firebase/firebase-config";
import { removeIdField } from "./functions";

const db = firebase.database();

const debug = false;
const attach = false;
const trace = false;

export const onRecord = (collection, callback, insertId = true) => {
  attach && console.log("Attache to collection", collection);
  db.ref(collection).on("value", (data) => {
    trace && console.log("Got value for collection", collection, data.val());
    const value = data.val();
    return callback(
      (insertId &&
        typeof value == "object" &&
        value !== null &&
        (value.id = data.key) &&
        value) ||
        value
    );
  });
  db.ref(collection).on("child_added", (data) => {
    trace && console.log("Child added into collection", collection, data.val());
  });
  db.ref(collection).on("child_changed", (data) => {
    trace && console.log("Child changed in collection", collection, data.val());
  });
  db.ref(collection).on("child_moved", (data) => {
    trace && console.log("Child moved in collection", collection, data.val());
  });
  db.ref(collection).on("child_removed", (data) => {
    trace && console.log("Child removed in collection", collection, data.val());
  });
  return () => off(collection);
};

export const fillIds = (data) =>
  Object.entries(data || {}).map(([slug, value]) => (value.id = slug) && value);

export const onList = (collection, callback) => {
  onRecord(collection, (data) => callback(fillIds(data)), false);
  return () => off(collection);
};

export const off = (collection) => {
  attach && console.log("Detach collection", collection);
  // console.trace("Off method");
  return db.ref(collection).off();
};

export const getRecords = (collection, callback) => {
  db.ref(collection)
    .once("value")
    .then((data) => callback(fillIds(data.val())))
    .catch((err) => console.log(err))
    .finally(
      () => debug && console.log("Finish reading a recrod from DB", collection)
    );
};

export const getOneRecord = (collection, callback) => {
  db.ref(collection)
    .once("value")
    .then((data) => callback(data.val()))
    .catch((err) => console.log(err))
    .finally(
      () => debug && console.log("Finish reading a recrod from DB", collection)
    );
};

export const addRecord = (collection, record) => {
  debug && console.log("Add record to DB collection", collection, record);
  const rec = { ...record };
  db.ref(collection)
    .push(rec, (err) => debug && console.log("Completed add", err))
    .then((val) => debug && console.log("Added to DB", val))
    .catch((err) => console.log(err))
    .finally(() => debug && console.log("Done with DB"));
};

export const updateRecord = (collection, record) => {
  debug && console.log("UPdate record in DB collection", collection, record);
  const rec = removeIdField({ ...record });
  db.ref([collection, record.id].join("/"))
    .update(rec, (err) => debug && console.log("Completed update", err))
    .then((val) => debug && console.log("Updated in DB", val, rec))
    .catch((err) => console.log(err))
    .finally(() => debug && console.log("Done with update in DB"));
};

export const replaceRecord = (collection, record) => {
  debug && console.log("Replace record in DB collection", collection, record);
  const rec = removeIdField({ ...record });
  db.ref([collection, record.id].join("/"))
    .set(rec, (err) => debug && console.log("Completed replace", err))
    .then((val) => debug && console.log("Replaced in DB", val))
    .catch((err) => console.log(err))
    .finally(() => debug && console.log("Done with replcace in DB"));
};

export const deleteRecord = (collection, record) => {
  debug && console.log("Delete record from DB collection", collection, record);
  db.ref(collection + "/" + record.id)
    .remove((err) => debug && console.log("Completed remove", err))
    .then((val) => debug && console.log("Deleted from DB", val))
    .catch((err) => console.log(err))
    .finally(() => debug && console.log("Done with delete from DB"));
};
