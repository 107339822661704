import React from "react";
import { Female, Male } from "@mui/icons-material";
import { Avatar, Badge } from "@mui/material";

export const NumberAvatar = ({ item, size }) => (
  <Badge
    overlap="circular"
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    badgeContent={
      item?.role &&
      (item.role === "leader" ? (
        <Male style={{color: "#17e"}} />
      ) : (
        <Female style={{color: "#e16"}} />
      ))
    }
  >
    <Avatar
      sx={size === "small" ? { width: 32, height: 32, fontSize: "1rem" } : null}
    >
      {item.number}
    </Avatar>
  </Badge>
);
