import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";

export const EditEventModal = ({ item, isVisible, onClose, onSave }) => {
  const [data, setData] = useState(item);
  const [isValid, setIsValid] = useState(true);

  const close = () => {
    onClose();
  };

  useEffect(() => {
    isVisible && setData({ public: false, ...item });
    isVisible && setIsValid(true);
  }, [item, isVisible]);

  return (
    <Dialog open={isVisible} onClose={close} onBackdropClick={close}>
      <DialogTitle>{!item ? "Add a new" : "Edit"} event</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          margin="dense"
          id="title"
          label="Title"
          error={!data?.title}
          value={data?.title || ""}
          onChange={(event) => setData({ ...data, title: event.target.value })}
        />
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={5}
          margin="normal"
          id="description"
          label="Description"
          value={data?.description || ""}
          onChange={(event) =>
            setData({ ...data, description: event.target.value })
          }
        />
        <FormControl fullWidth margin="normal">
          <DatePicker
            // disablePast
            label="Date"
            value={data?.startDate || ""}
            mask="__.__.____"
            onError={(reason) => setIsValid(!reason)}
            onChange={(newValue) => {
              setData({ ...data, startDate: new Date(newValue).toISOString() });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
        <FormControlLabel
          label="Public"
          control={
            <Checkbox
              checked={data?.public || false}
              onChange={(event) => {
                setData({ ...data, public: event.target.checked });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button type="reset" onClick={close}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!data?.title || !isValid}
          onClick={() => {
            onSave({ ...data });
            close();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
